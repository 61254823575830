@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply box-border;
}

html {
  @apply scroll-smooth;
}

body {
  @apply m-0 p-0 font-primary text-md text-black font-normal overflow-y-scroll overflow-x-hidden;
}

a {
  @apply no-underline hover:underline text-primary;
}

.section {
  @apply px-4 md:px-10;
}
